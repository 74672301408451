import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180')
];

export const server_loads = [0,2,6,11,12,8,9,10,16,19,22,26,27,24,25,28,46,48,49,32,33,34,37,40,44,42,43];

export const dictionary = {
		"/": [~50],
		"/[[lang=lang]]/(main)/admin": [~51,[2,4],[3,5]],
		"/[[lang=lang]]/(main)/admin/applicants": [52,[2,4],[3,5]],
		"/[[lang=lang]]/(main)/admin/applicants/[type]": [53,[2,4],[3,5]],
		"/[[lang=lang]]/(main)/admin/applicants/[type]/[id]": [54,[2,4,6],[3,5]],
		"/[[lang=lang]]/(main)/admin/applicants/[type]/[id]/contact-history": [~55,[2,4,6],[3,5]],
		"/[[lang=lang]]/(main)/admin/applicants/[type]/[id]/jobs": [56,[2,4,6],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers": [57,[2,4,7],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/stores": [68,[2,4,7],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/stores/[id]": [~69,[2,4,11],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/stores/[id]/employees": [70,[2,4,11],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/stores/[id]/employees/[userId]": [71,[2,4,12],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/stores/[id]/job-history": [72,[2,4,11],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/stores/[id]/open-jobs": [73,[2,4,11],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/[id]": [~58,[2,4,8],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/[id]/job-postings": [59,[2,4,8],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/[id]/price-catalog": [~60,[2,4,8],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/[id]/store-managers": [61,[2,4,8],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/[id]/stores": [62,[2,4,8],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]": [~63,[2,4,9],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]/employees": [64,[2,4,9],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]/employees/[userId]": [65,[2,4,10],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]/job-history": [66,[2,4,9],[3,5]],
		"/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]/open-jobs": [67,[2,4,9],[3,5]],
		"/[[lang=lang]]/(main)/admin/dashboard": [74,[2,4],[3,5]],
		"/[[lang=lang]]/(main)/admin/job-postings": [75,[2,4,13],[3,5]],
		"/[[lang=lang]]/(main)/admin/job-postings/[type=job_posting_type]": [76,[2,4,13],[3,5]],
		"/[[lang=lang]]/(main)/admin/job-postings/[type=job_posting_type]/[id]": [77,[2,4],[3,5]],
		"/[[lang=lang]]/(main)/admin/platform-settings": [~78,[2,4,14],[3,5]],
		"/[[lang=lang]]/(main)/admin/platform-settings/admins": [79,[2,4,14],[3,5]],
		"/[[lang=lang]]/(main)/admin/profile": [~80,[2,4],[3,5]],
		"/[[lang=lang]]/(main)/admin/recruiters": [81,[2,4,15],[3,5]],
		"/[[lang=lang]]/(main)/admin/recruiters/teams": [83,[2,4,15],[3,5]],
		"/[[lang=lang]]/(main)/admin/recruiters/[id]": [82,[2,4,15],[3,5]],
		"/[[lang=lang]]/auth": [~165,[45]],
		"/[[lang=lang]]/auth/confirm": [~166],
		"/[[lang=lang]]/auth/set-password": [~167,[45]],
		"/[[lang=lang]]/auth/sign-out": [~168,[45]],
		"/[[lang=lang]]/(main)/common/applicants": [84,[2],[3]],
		"/[[lang=lang]]/(main)/common/applicants/[id]": [85,[2],[3]],
		"/[[lang=lang]]/(main)/common/employees": [86,[2],[3]],
		"/[[lang=lang]]/(main)/common/job-postings": [87,[2],[3]],
		"/[[lang=lang]]/(main)/common/job-postings/[id]": [88,[2],[3]],
		"/[[lang=lang]]/(main)/common/profiles": [89,[2],[3]],
		"/[[lang=lang]]/(main)/common/stores/[id]": [90,[2],[3]],
		"/[[lang=lang]]/(main)/customer": [~91,[2,16],[3,17]],
		"/[[lang=lang]]/(main)/customer/company-settings": [~92,[2,16],[3,17]],
		"/[[lang=lang]]/(main)/customer/dashboard": [93,[2,16],[3,17]],
		"/[[lang=lang]]/(main)/customer/hubs": [94,[2,16],[3,17]],
		"/[[lang=lang]]/(main)/customer/hubs/certify-hub": [95,[2,16,18],[3,17]],
		"/[[lang=lang]]/(main)/customer/hubs/certify-hub/[courseId]": [~96,[2,16,18,19],[3,17]],
		"/[[lang=lang]]/(main)/customer/hubs/certify-hub/[courseId]/[videoIndex]": [~97,[2,16,18,19],[3,17]],
		"/[[lang=lang]]/(main)/customer/hubs/health-pass": [~98,[2,16,20],[3,17]],
		"/[[lang=lang]]/(main)/customer/hubs/health-pass/checkout": [101,[2,16,20],[3,17]],
		"/[[lang=lang]]/(main)/customer/hubs/health-pass/[type=health_pass_type]": [99,[2,16,20],[3,17]],
		"/[[lang=lang]]/(main)/customer/hubs/health-pass/[userId]": [100,[2,16,20],[3,17]],
		"/[[lang=lang]]/(main)/customer/profile": [~102,[2,16],[3,17]],
		"/[[lang=lang]]/(main)/customer/recruiting": [103,[2,16,21],[3,17]],
		"/[[lang=lang]]/(main)/customer/recruiting/accepted": [104,[2,16,21],[3,17]],
		"/[[lang=lang]]/(main)/customer/recruiting/job-postings": [~105,[2,16,21],[3,17]],
		"/[[lang=lang]]/(main)/customer/recruiting/job-postings/[id]": [106,[2,16],[3,17]],
		"/[[lang=lang]]/(main)/customer/recruiting/job-postings/[id]/applicants": [~107,[2,16,21],[3,17]],
		"/[[lang=lang]]/(main)/customer/recruiting/job-postings/[id]/applicants/[applicant_id]": [108,[2,16,22],[3,17]],
		"/[[lang=lang]]/(main)/customer/stores": [109,[2,16,23],[3,17]],
		"/[[lang=lang]]/(main)/customer/stores/employees": [115,[2,16,23],[3,17]],
		"/[[lang=lang]]/(main)/customer/stores/employees/[id]": [116,[2,16,26],[3,17]],
		"/[[lang=lang]]/(main)/customer/stores/store-managers": [117,[2,16,23],[3,17]],
		"/[[lang=lang]]/(main)/customer/stores/store-managers/[userId]": [118,[2,16,27],[3,17]],
		"/[[lang=lang]]/(main)/customer/stores/store-managers/[userId]/stores": [119,[2,16,27],[3,17]],
		"/[[lang=lang]]/(main)/customer/stores/[id]": [~110,[2,16,24],[3,17]],
		"/[[lang=lang]]/(main)/customer/stores/[id]/employees": [111,[2,16,24],[3,17]],
		"/[[lang=lang]]/(main)/customer/stores/[id]/employees/[userId]": [112,[2,16,25],[3,17]],
		"/[[lang=lang]]/(main)/customer/stores/[id]/job-history": [113,[2,16,24],[3,17]],
		"/[[lang=lang]]/(main)/customer/stores/[id]/open-jobs": [114,[2,16,24],[3,17]],
		"/datenschutz": [180],
		"/[[lang=lang]]/(main)/employee": [120,[2,28],[3,29]],
		"/[[lang=lang]]/(main)/employee/dashboard": [~121,[2,28],[3,29]],
		"/[[lang=lang]]/(main)/employee/profile": [~122,[2,28],[3,29]],
		"/[[lang=lang]]/(main)/employee/skills": [123,[2,28],[3,29]],
		"/[[lang=lang]]/health-pass": [~169,[46],[47]],
		"/[[lang=lang]]/health-pass/certificate": [~170,[46,48],[47]],
		"/[[lang=lang]]/health-pass/certificate/download": [~171,[46,48],[47]],
		"/[[lang=lang]]/health-pass/quiz": [172,[46,49],[47]],
		"/[[lang=lang]]/health-pass/quiz/answering": [173,[46,49],[47]],
		"/[[lang=lang]]/health-pass/quiz/answering/review": [~175,[46,49],[47]],
		"/[[lang=lang]]/health-pass/quiz/answering/success": [~176,[46,49],[47]],
		"/[[lang=lang]]/health-pass/quiz/answering/[question=question]": [~174,[46,49],[47]],
		"/[[lang=lang]]/health-pass/quiz/video": [~177,[46,49],[47]],
		"/[[lang=lang]]/health-pass/verification": [~178,[46],[47]],
		"/[[lang=lang]]/(main)/recruiter": [~124,[2,30],[3,31]],
		"/[[lang=lang]]/(main)/recruiter/applicants": [125,[2,30],[3,31]],
		"/[[lang=lang]]/(main)/recruiter/applicants/[type]": [126,[2,30],[3,31]],
		"/[[lang=lang]]/(main)/recruiter/applicants/[type]/[id]": [127,[2,30,32],[3,31]],
		"/[[lang=lang]]/(main)/recruiter/applicants/[type]/[id]/contact-history": [~128,[2,30,32],[3,31]],
		"/[[lang=lang]]/(main)/recruiter/applicants/[type]/[id]/jobs": [129,[2,30,32],[3,31]],
		"/[[lang=lang]]/(main)/recruiter/dashboard": [130,[2,30],[3,31]],
		"/[[lang=lang]]/(main)/recruiter/job-postings": [131,[2,30],[3,31]],
		"/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]": [132,[2,30],[3,31]],
		"/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]": [133,[2,30],[3,31]],
		"/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/applicants": [~134,[2,30],[3,31]],
		"/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/applicants/[applicant_id]": [135,[2,30,33],[3,31]],
		"/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/applicants/[applicant_id]/contact-history": [~136,[2,30,33],[3,31]],
		"/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/applicants/[applicant_id]/jobs": [137,[2,30,33],[3,31]],
		"/[[lang=lang]]/(main)/recruiter/profile": [~138,[2,30],[3,31]],
		"/[[lang=lang]]/(main)/redirect": [139,[2],[3]],
		"/[[lang=lang]]/(main)/store-manager": [~140,[2,34],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/dashboard": [141,[2,34],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/hubs": [142,[2,34],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/hubs/certify-hub": [143,[2,34,36],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/hubs/certify-hub/[courseId]": [~144,[2,34,36,37],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/hubs/certify-hub/[courseId]/[videoIndex]": [~145,[2,34,36,37],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/hubs/health-pass": [~146,[2,34,38],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/hubs/health-pass/checkout": [149,[2,34,38],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/hubs/health-pass/[type=health_pass_type]": [147,[2,34,38],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/hubs/health-pass/[userId]": [148,[2,34,38],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/profile": [~150,[2,34],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/recruiting": [151,[2,34,39],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/recruiting/accepted": [152,[2,34,39],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/recruiting/job-postings": [~153,[2,34,39],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/recruiting/job-postings/[id]": [154,[2,34],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/recruiting/job-postings/[id]/applicants": [~155,[2,34,39],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/recruiting/job-postings/[id]/applicants/[applicant_id]": [156,[2,34,40],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/stores": [157,[2,34,41],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/stores/employees": [163,[2,34,41],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/stores/employees/[id]": [164,[2,34,44],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/stores/[id]": [~158,[2,34,42],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/stores/[id]/employees": [159,[2,34,42],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/stores/[id]/employees/[userId]": [160,[2,34,43],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/stores/[id]/job-history": [161,[2,34,42],[3,35]],
		"/[[lang=lang]]/(main)/store-manager/stores/[id]/open-jobs": [162,[2,34,42],[3,35]],
		"/[lang=lang]": [~179]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';